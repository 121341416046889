import { pick } from 'lodash';
import { isSameDay, isValid, toDate } from 'date-fns';
import {
  Address,
  AddressModelKeys,
  AttendeeWizardState,
  Conference,
  ConferenceKeys,
  ConferenceRegistrationAddress,
  ConferenceRegistrationAddressKeys,
  ConferenceRegistrationAwardName,
  ConferenceRegistrationAwardNameKeys,
  ConferenceRegistrationEmail,
  ConferenceRegistrationEmailKeys,
  ConferenceRegistrationExcursion,
  ConferenceRegistrationExcursionsKeys,
  ConferenceRegistrationGuestsConfiguration,
  ConferenceRegistrationGuestsConfigurationKeys,
  ConferenceRegistrationPersonalInfo,
  ConferenceRegistrationPersonalInfoKeys,
  ConferenceRegistrationRSVP,
  ConferenceRegistrationRSVPKeys,
  ConferenceRegistrationStepName,
  conferenceRegistrationStepsMap,
  ConferenceStepsConfigurationKeys,
  FlightInfoKeys,
  HotelReservationKeys,
  InviteeStatus,
  Registrant,
  RegistrantData,
  RegistrantKeys,
  RegistrantModelKeys,
  RegistrantWizardStateKeys,
  RequestOutcomeKeys,
  TravelMode,
  WizardStepState,
} from '@ag-common-lib/public-api';

export function pickRSVPData(data: RegistrantData): ConferenceRegistrationRSVP {
  return pick(data, ConferenceRegistrationRSVPKeys);
}

export function pickGuestsConfigurationData(data: RegistrantData): ConferenceRegistrationGuestsConfiguration {
  return pick(data, ConferenceRegistrationGuestsConfigurationKeys);
}

export function pickPersonalInfoData(data: RegistrantData): ConferenceRegistrationPersonalInfo {
  return pick(data, ConferenceRegistrationPersonalInfoKeys);
}

export function pickEmailData(data: RegistrantData): ConferenceRegistrationEmail {
  return pick(data, ConferenceRegistrationEmailKeys);
}

export function pickAddressesData(data: RegistrantData): ConferenceRegistrationAddress {
  return pick(data, ConferenceRegistrationAddressKeys);
}

export function pickExcursionData(data: RegistrantData): ConferenceRegistrationExcursion {
  return pick(data, ConferenceRegistrationExcursionsKeys);
}

export function pickAddressData(address: Address) {
  return pick(address, [
    AddressModelKeys.address1,
    AddressModelKeys.address2,
    AddressModelKeys.city,
    AddressModelKeys.state,
    AddressModelKeys.zip,
    AddressModelKeys.country,
    AddressModelKeys.county,
    AddressModelKeys.isPrimaryBilling,
    AddressModelKeys.isPrimaryShipping,
  ]);
}

export function pickAwardNameData(data: RegistrantData): ConferenceRegistrationAwardName {
  return pick(data, ConferenceRegistrationAwardNameKeys);
}

export const isRequestedDifferentHotelCheckInCheckOutDates = (
  arrivalDate: string,
  departureDate: string,
  requestedArrivalDate: string,
  requestedDepartureDate: string,
): boolean => {
  const arrivalDateFsn = toDate(arrivalDate);
  const departureDateFsn = toDate(departureDate);
  const requestedArrivalDateFsn = toDate(requestedArrivalDate);
  const requestedDepartureDateFsn = toDate(requestedDepartureDate);
  if (
    ![arrivalDateFsn, departureDateFsn, requestedArrivalDateFsn, requestedDepartureDateFsn].map(toDate).every(isValid)
  ) {
    return false;
  }

  return !isSameDay(arrivalDateFsn, requestedArrivalDateFsn) || !isSameDay(departureDateFsn, requestedDepartureDateFsn);
};

export const calculateWizardState = (registrant: Registrant, conference: Conference = null) => {
  const wizardState = registrant?.[RegistrantModelKeys.wizardState];
  const supportItemsNotArchived = registrant?.[RegistrantModelKeys.statistic]?.supportItemsNotArchived;
  if (!!supportItemsNotArchived) {
    debugger;
    return AttendeeWizardState.supportRequested;
  }

  const stepInProgress = getCurrentStepInProgress({
    conference,
    conferenceRegistration: registrant,
  });

  const registrantData = registrant?.[RegistrantModelKeys.data];
  const guestsEnabled = conference?.[ConferenceKeys.guestsEnabled];
  const guestsStepIsDone =
    wizardState?.[ConferenceRegistrationStepName.registrantGuestConfigurationStep] === WizardStepState.done;
  const flightsEnabled = conference?.[ConferenceKeys.flightsEnabled];
  const flightsStepIsDone =
    wizardState?.[ConferenceRegistrationStepName.registrantFlightInformationStep] === WizardStepState.done;
  const hotelEnabled = conference?.[ConferenceKeys.hotelEnabled];
  const hotelReservationStepIsDone =
    wizardState?.[ConferenceRegistrationStepName.registrantHotelReservationStep] === WizardStepState.done;

  if (guestsEnabled && guestsStepIsDone && checkAdditionalGuestRequestOutcomeState(registrantData)) {
    return AttendeeWizardState.supportRequested;
  }

  if (flightsEnabled && flightsStepIsDone && checkNotResolvedFlightTravelDatesDiffer(registrantData)) {
    return AttendeeWizardState.supportRequested;
  }

  if (hotelEnabled && hotelReservationStepIsDone && checkHotelReservationRequestOutcomeState(registrantData)) {
    return AttendeeWizardState.supportRequested;
  }

  if (stepInProgress?.name === ConferenceRegistrationStepName.registrationSummaryStep) {
    return AttendeeWizardState.completedAndSubmitted;
  }

  if (!wizardState) {
    return AttendeeWizardState.initial;
  }

  if (wizardState?.[RegistrantWizardStateKeys.isSubmitted]) {
    return AttendeeWizardState.initialRegistrationSubmitted;
  }

  return AttendeeWizardState.inProgress;
};

export const checkAdditionalGuestRequestOutcomeState = (registrantData: RegistrantData): boolean => {
  // Request for additional Guests
  return (
    registrantData?.[RegistrantKeys.additionalGuestRequested] &&
    !registrantData?.[RegistrantKeys.additionalGuestRequestOutcome]?.[RequestOutcomeKeys.state]
  );
};

export const checkNotResolvedFlightTravelDatesDiffer = (registrantData: RegistrantData): boolean => {
  // Request for different Travel Dates
  const flightInformation = registrantData?.[RegistrantKeys.flightInformation];
  return (
    flightInformation?.[FlightInfoKeys.travelDatesDiffer] &&
    !flightInformation?.[FlightInfoKeys.travelDatesDifferRequestOutcome]?.[RequestOutcomeKeys.state]
  );
};

export const checkHotelReservationRequestOutcomeState = (registrantData: RegistrantData): boolean => {
  const hotelReservation = registrantData?.[RegistrantKeys.hotelReservation];
  // Request for different Additional Rooms
  const hasNotResolvedDifferentAdditionalRooms =
    hotelReservation?.[HotelReservationKeys.additionalRoomRequested] &&
    !hotelReservation?.[HotelReservationKeys.additionalRoomRequestOutcome]?.[RequestOutcomeKeys.state];
  // Request for different Hotel Booking Dates
  const hasDifferentTravelDates = isRequestedDifferentHotelCheckInCheckOutDates(
    hotelReservation?.[HotelReservationKeys.checkInDate],
    hotelReservation?.[HotelReservationKeys.checkOutDate],
    hotelReservation?.[HotelReservationKeys.requestedCheckInDate],
    hotelReservation?.[HotelReservationKeys.requestedCheckOutDate],
  );
  const hasNotResolvedDifferentHotelBookingDates =
    hasDifferentTravelDates &&
    !hotelReservation?.[HotelReservationKeys.requestDifferBookingDatesOutcome]?.[RequestOutcomeKeys.state];
  return [hasNotResolvedDifferentAdditionalRooms, hasNotResolvedDifferentHotelBookingDates].some(Boolean);
};

export function calculateSummaryStepWizardState(
  registrantData: RegistrantData,
  conference: Conference,
): AttendeeWizardState {
  if (conference?.[ConferenceKeys.guestsEnabled] && checkAdditionalGuestRequestOutcomeState(registrantData)) {
    return AttendeeWizardState.supportRequested;
  }

  if (conference?.[ConferenceKeys.flightsEnabled] && checkNotResolvedFlightTravelDatesDiffer(registrantData)) {
    return AttendeeWizardState.supportRequested;
  }

  if (conference?.[ConferenceKeys.hotelEnabled] && checkHotelReservationRequestOutcomeState(registrantData)) {
    return AttendeeWizardState.supportRequested;
  }

  return AttendeeWizardState.completedAndSubmitted;
}

export function prepareStepsToComplete({
  conference,
  conferenceRegistration,
}: {
  conference: Conference;
  conferenceRegistration: Registrant;
}): any {
  const wizardState = conferenceRegistration?.[RegistrantModelKeys.wizardState] ?? {};
  const guestsIds = conferenceRegistration?.[RegistrantModelKeys.guestsIds];
  const state = {};

  conferenceRegistrationStepsMap.forEach((step, stepName) => {
    switch (stepName) {
      case ConferenceRegistrationStepName.rsvpStep:
      case ConferenceRegistrationStepName.registrationConfirmationStep:
      case ConferenceRegistrationStepName.registrantPaymentStep:
        if (wizardState?.[stepName] !== WizardStepState.done) {
          Object.assign(state, {
            [stepName]: WizardStepState.skipped,
          });
        }

        return;

      case ConferenceRegistrationStepName.registrantPersonalInformationStep:
        step?.steps?.forEach(subStep => {
          const subStepName = subStep?.name;
          if (wizardState?.[subStepName] !== WizardStepState.done) {
            Object.assign(state, {
              [subStepName]: WizardStepState.skipped,
            });
          }
        });
        return;

      case ConferenceRegistrationStepName.registrantHotelReservationStep:
        if (conference?.[ConferenceKeys.hotelActive] && wizardState?.[stepName] !== WizardStepState.done) {
          Object.assign(state, {
            [stepName]: WizardStepState.skipped,
          });
        }
        return;

      case ConferenceRegistrationStepName.registrantFlightInformationStep:
        if (wizardState?.[ConferenceRegistrationStepName.registrantFlightYourSelectionStep] !== WizardStepState.done) {
          Object.assign(state, {
            [ConferenceRegistrationStepName.registrantFlightYourSelectionStep]: WizardStepState.skipped,
          });
        }

        guestsIds
          ?.map(guestId => ({ data: { registrantGuestDbId: guestId }, name: `${stepName}_${guestId}` }))
          ?.forEach(guestStep => {
            if (wizardState?.[guestStep.name] !== WizardStepState.done) {
              Object.assign(state, {
                [guestStep.name]: WizardStepState.skipped,
              });
            }
          });

        return;

      case ConferenceRegistrationStepName.registrantExcursionsStep:
        if (!conference?.[ConferenceKeys.excursionsActive]) {
          return;
        }
        if (wizardState?.[stepName] !== WizardStepState.done) {
          Object.assign(state, {
            [stepName]: WizardStepState.skipped,
          });
        }

        if (
          wizardState?.[ConferenceRegistrationStepName.registrantExcursionYourSelectionStep] !== WizardStepState.done
        ) {
          Object.assign(state, {
            [ConferenceRegistrationStepName.registrantExcursionYourSelectionStep]: WizardStepState.skipped,
          });
        }

        guestsIds
          ?.map(guestId => ({ data: { registrantGuestDbId: guestId }, name: `${stepName}_${guestId}` }))
          ?.forEach(guestStep => {
            if (wizardState?.[guestStep.name] !== WizardStepState.done) {
              Object.assign(state, {
                [guestStep.name]: WizardStepState.skipped,
              });
            }
          });

        return;

      case ConferenceRegistrationStepName.registrantGuestConfigurationStep:
        if (!conference?.[ConferenceKeys.guestsActive]) {
          return;
        }

        if (wizardState?.[stepName] !== WizardStepState.done) {
          Object.assign(state, {
            [stepName]: WizardStepState.skipped,
          });
        }

        guestsIds
          ?.map(guestId => ({ data: { registrantGuestDbId: guestId }, name: `${stepName}_${guestId}` }))
          ?.forEach(guestStep => {
            if (wizardState?.[guestStep.name] !== WizardStepState.done) {
              Object.assign(state, {
                [guestStep.name]: WizardStepState.skipped,
              });
            }
          });
        return;

      default:
        break;
    }
  });

  return state;
}

export function getCurrentStepInProgress({
  sortedGuestsIds,
  conference,
  conferenceRegistration,
}: {
  sortedGuestsIds?: string[];
  conference: Conference;
  conferenceRegistration: Registrant;
}): {
  name: any;
  data?: {
    registrantGuestDbId?: string;
  };
  title?: string;
} {
  const wizardState = conferenceRegistration?.[RegistrantModelKeys.wizardState] ?? {};
  const guestsIds = sortedGuestsIds ?? conferenceRegistration?.[RegistrantModelKeys.guestsIds];
  const stepNameTitleMap = new Map<ConferenceRegistrationStepName, string>([
    [ConferenceRegistrationStepName.rsvpStep, 'Initial'],
    [ConferenceRegistrationStepName.registrantPersonalInformationStep, 'In Progress: Personal Information'],
    [ConferenceRegistrationStepName.registrantGuestConfigurationStep, 'In Progress: Guest(s) Information'],
    [ConferenceRegistrationStepName.registrantHotelReservationStep, 'In Progress: Hotel Reservation'],
    [ConferenceRegistrationStepName.registrantFlightInformationStep, 'In Progress: Flight Information'],
    [ConferenceRegistrationStepName.registrantExcursionsStep, 'In Progress: Excursions'],
    [ConferenceRegistrationStepName.registrationConfirmationStep, 'In Progress: Confirmation'],
    [ConferenceRegistrationStepName.registrantPaymentStep, 'In Progress: Payment'],
    [ConferenceRegistrationStepName.registrationSummaryStep, 'Done'],
  ]);

  const registrantData = conferenceRegistration?.[RegistrantModelKeys.data];
  const inviteeStatus = registrantData?.[RegistrantKeys.inviteeStatus];

  if (inviteeStatus === InviteeStatus.declined) {
    return { title: 'Done: Declined', name: ConferenceRegistrationStepName.registrationSummaryStep };
  }

  for (const [stepName, step] of Array.from(conferenceRegistrationStepsMap.entries())) {
    switch (stepName) {
      case ConferenceRegistrationStepName.rsvpStep:
      case ConferenceRegistrationStepName.registrationConfirmationStep:
      case ConferenceRegistrationStepName.registrationSummaryStep:
      case ConferenceRegistrationStepName.registrantPaymentStep:
        if (!wizardState?.[stepName]) {
          return Object.assign({ title: stepNameTitleMap.get(stepName) }, { name: stepName });
        }
        break;
      case ConferenceRegistrationStepName.registrantPersonalInformationStep:
        const personalInfoSubSteps = step?.steps.filter(subStep => {
          const excludedSections = getExcludedSections(conference, stepName) as ConferenceRegistrationStepName[];
          return !excludedSections.includes(subStep.name as ConferenceRegistrationStepName);
        });
        const personalInformationSubStep = personalInfoSubSteps.find(subStep => !wizardState?.[subStep?.name]);
        if (personalInformationSubStep) {
          return Object.assign({}, personalInformationSubStep, {
            title: stepNameTitleMap.get(ConferenceRegistrationStepName.registrantPersonalInformationStep),
          });
        }
        break;
      case ConferenceRegistrationStepName.registrantHotelReservationStep:
        if (conference?.[ConferenceKeys.hotelActive] && !wizardState?.[stepName]) {
          return Object.assign({ title: stepNameTitleMap.get(stepName) }, { name: stepName });
        }
        break;
      case ConferenceRegistrationStepName.registrantFlightInformationStep:
        if (!conference?.[ConferenceKeys.flightsActive]) {
          break;
        }
        if (!wizardState?.[ConferenceRegistrationStepName.registrantFlightYourSelectionStep]) {
          return Object.assign(
            { title: stepNameTitleMap.get(ConferenceRegistrationStepName.registrantFlightInformationStep) },
            { name: ConferenceRegistrationStepName.registrantFlightYourSelectionStep },
          );
        }

        if (wizardState?.[ConferenceRegistrationStepName.registrantFlightYourSelectionStep] === WizardStepState.done) {
          break;
        }
        const flightInformation =
          conferenceRegistration?.[RegistrantModelKeys.data]?.[RegistrantKeys.flightInformation];

        const equalTravelMode = flightInformation?.[FlightInfoKeys.equalTravelMode];
        const travelMode = flightInformation?.[FlightInfoKeys.travelMode];
        const isBookOwnFlights = flightInformation?.[FlightInfoKeys.isBookOwnFlights];

        if (equalTravelMode && (isBookOwnFlights || travelMode === TravelMode.driving)) {
          break;
        }
        const guestFlightStep = guestsIds
          ?.map(guestId => ({ data: { registrantGuestDbId: guestId }, name: `${stepName}_${guestId}` }))
          ?.find(guestStep => !wizardState?.[guestStep?.name]);

        if (guestFlightStep) {
          return Object.assign(
            { title: stepNameTitleMap.get(ConferenceRegistrationStepName.registrantFlightInformationStep) },
            guestFlightStep,
          );
        }

        break;
      case ConferenceRegistrationStepName.registrantExcursionsStep:
        if (!conference?.[ConferenceKeys.excursionsActive]) {
          break;
        }
        if (!wizardState?.[stepName]) {
          return {
            name: stepName,
            title: stepNameTitleMap.get(ConferenceRegistrationStepName.registrantExcursionsStep),
          };
        }

        if (wizardState?.[stepName] === WizardStepState.done) {
          break;
        }

        if (!wizardState?.[ConferenceRegistrationStepName.registrantExcursionYourSelectionStep]) {
          return Object.assign(
            { title: stepNameTitleMap.get(ConferenceRegistrationStepName.registrantExcursionsStep) },
            { name: ConferenceRegistrationStepName.registrantExcursionYourSelectionStep },
          );
        }

        const guestExcursionStep = guestsIds
          ?.map(guestId => ({ data: { registrantGuestDbId: guestId }, name: `${stepName}_${guestId}` }))
          ?.find(guestStep => !wizardState?.[guestStep?.name]);

        if (guestExcursionStep) {
          return Object.assign(
            { title: stepNameTitleMap.get(ConferenceRegistrationStepName.registrantExcursionsStep) },
            guestExcursionStep,
          );
        }

        break;
      case ConferenceRegistrationStepName.registrantGuestConfigurationStep:
        if (!conference?.[ConferenceKeys.guestsActive]) {
          break;
        }

        if (!wizardState?.[stepName]) {
          return Object.assign({ title: stepNameTitleMap.get(stepName) }, { name: stepName });
        }

        if (wizardState?.[stepName] === WizardStepState.done) {
          break;
        }

        const guestConfigurationStep = guestsIds
          ?.map(guestId => ({ data: { registrantGuestDbId: guestId }, name: `${stepName}_${guestId}` }))
          ?.find(guestStep => !wizardState?.[guestStep?.name]);

        if (guestConfigurationStep) {
          return Object.assign(
            { title: stepNameTitleMap.get(ConferenceRegistrationStepName.registrantGuestConfigurationStep) },
            guestConfigurationStep,
          );
        }
        break;
    }
  }

  return null;
}

const getExcludedSections = (conference: Conference, stepName) =>
  conference?.[ConferenceKeys.stepsConfiguration]?.find(
    config => config[ConferenceStepsConfigurationKeys.stepName] === stepName,
  )?.[ConferenceStepsConfigurationKeys.excludedSections] ?? [];
