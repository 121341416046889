import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AttendeeType,
  ChangeSourceType,
  DX_USD_CURRENCY_FORMAT,
  Excursion,
  ExcursionConfigurationKeys,
  ExcursionDataType,
  ExcursionKeys,
  ExcursionOptionKey,
  ExcursionPreferenceKeys,
  ExcursionStatistic,
  ExcursionStatisticKeys,
  GuestKeys,
  LookupKeys,
  Messages,
  MMDDYYYY_DATE_FORMAT,
  RegistrantKeys,
  RegistrantModelKeys,
  SelectedExcursionConfiguration,
  SelectedExcursionOptions,
  SelectedExcursions,
  SelectedExcursionsKeys,
  MILITARY_TIME_FORMAT,
  TRUE_FALSE_LOOKUP,
} from '@ag-common-lib/public-api';
import { ExcursionInfoTableData, ExcursionInfoTableDataKeys } from './excursions-info-table.models';
import { RowUpdatingEvent, RowValidatingEvent } from 'devextreme/ui/data_grid';
import { DxDataGridComponent } from 'devextreme-angular';
import { set } from 'lodash';
import { ConferenceGuestsService } from '../../services/conference-guests.service';
import { ConferenceRegistrantsService } from '../../services/conference-registrants/conference-registrants.service';
import { deleteField } from 'firebase/firestore';
import { confirm } from 'devextreme/ui/dialog';
import { ExcursionConfigurationDisplayValuePipe } from 'ag-common-svc/shared/pipes/excursion-configuration-display-value.pipe';

@UntilDestroy()
@Component({
  selector: 'ag-shr-excursions-info-table',
  templateUrl: './excursions-info-table.component.html',
  styleUrls: ['./excursions-info-table.component.scss'],
  providers: [ExcursionConfigurationDisplayValuePipe],
})
export class ExcursionsInfoTableComponent {
  @ViewChild(DxDataGridComponent) dataGrid!: DxDataGridComponent;
  @ViewChild('totalTmp') totalComponent: TemplateRef<any>;
  @Output() goToExcursionSelection = new EventEmitter();
  @Input() selectedExcursionsDataSource: ExcursionInfoTableData[] = [];
  @Input() excursionStatisticsMap: Map<string, ExcursionStatistic>;
  @Input() excursionsBudget: number;
  @Input() conferenceExcursions: Excursion[] = [];
  @Input() canModify = false;
  @Input() canEdit = false;
  @Input() caption = '';
  @Input() isEditable = false;
  @Input() includeTotal = true;
  @Input() showConfigurations = true;
  @Input() sourceType: ChangeSourceType = null;

  protected readonly ExcursionInfoTableDataKeys = ExcursionInfoTableDataKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly ExcursionKeys = ExcursionKeys;
  protected readonly ExcursionStatisticKeys = ExcursionStatisticKeys;
  protected readonly ExcursionPreferenceKeys = ExcursionPreferenceKeys;
  protected readonly ExcursionOptionKey = ExcursionOptionKey;
  protected readonly requiredText = Messages.REQUIRED_TEXT;
  protected readonly DX_USD_CURRENCY_FORMAT = DX_USD_CURRENCY_FORMAT;
  protected readonly TRUE_FALSE_LOOKUP = TRUE_FALSE_LOOKUP;
  protected readonly ExcursionConfigurationKeys = ExcursionConfigurationKeys;
  protected readonly ExcursionDataType = ExcursionDataType;
  protected dateFormat = MMDDYYYY_DATE_FORMAT;
  protected readonly MILITARY_TIME_FORMAT = MILITARY_TIME_FORMAT;

  constructor(
    private excursionConfigurationDisplayValuePipe: ExcursionConfigurationDisplayValuePipe,
    private conferenceGuestsService: ConferenceGuestsService,
    private conferenceRegistrantsService: ConferenceRegistrantsService,
  ) {}

  protected setExcursionCellValue = async (updatedData, value: Excursion, currentRowData) => {
    const excursionId = value?.[ExcursionKeys.id];
    const excursionStatistics = this.excursionStatisticsMap.get(excursionId);
    const setExcursion = () => {
      Object.assign(updatedData, {
        [ExcursionInfoTableDataKeys.excursionId]: value?.[ExcursionKeys.id],
        [ExcursionInfoTableDataKeys.excursionType]: value?.[ExcursionKeys.type],
        [ExcursionInfoTableDataKeys.excursionName]: value?.[ExcursionKeys.name],
        [ExcursionInfoTableDataKeys.excursionCost]: value?.[ExcursionKeys.cost],
        [ExcursionInfoTableDataKeys.selectedPreferences]: Object.assign({}, new SelectedExcursionOptions()),
        [ExcursionInfoTableDataKeys.configurations]: Object.assign({}, new SelectedExcursionConfiguration()),
      });
    };

    if (!excursionStatistics) {
      setExcursion();
      return;
    }
    const seatsLeft = excursionStatistics?.[ExcursionStatisticKeys.seatsLeft];

    if (seatsLeft > 0) {
      setExcursion();
      return;
    }

    const isConfirmed = await confirm(
      `<i>Excursion is out of capacity.</i><br/><i>Are you sure you want to Continue?</i>`,
      'Confirm',
    );

    if (isConfirmed) {
      setExcursion();
    }
  };

  protected setExcursionPreferenceCellValue = (updatedData, [path, selectedPreferenceOption], currentRowData) => {
    Object.assign(updatedData, {
      [ExcursionInfoTableDataKeys.selectedPreferences]: Object.assign(
        {},
        currentRowData?.[ExcursionInfoTableDataKeys.selectedPreferences],
        {
          [path]: selectedPreferenceOption,
        },
      ),
    });
  };

  protected setExcursionConfigurationsCellValue = (updatedData, [path, value, dataType], currentRowData) => {
    Object.assign(updatedData, {
      [ExcursionInfoTableDataKeys.configurations]: Object.assign(
        {},
        currentRowData?.[ExcursionInfoTableDataKeys.configurations],
        {
          [path]: { value, dataType },
        },
      ),
    });
  };

  protected onRowUpdating = (e: RowUpdatingEvent<ExcursionInfoTableData>): void => {
    const data = Object.assign({}, e?.oldData, e?.newData);
    const conferenceId = data?.[ExcursionInfoTableDataKeys.conferenceDbId];
    const registrantDbId = data?.[ExcursionInfoTableDataKeys.registrantDbId];
    const attendeeType = data?.[ExcursionInfoTableDataKeys.attendeeType];
    const attendeeId = data?.[ExcursionInfoTableDataKeys.attendeeId];
    const excursionId = data?.[ExcursionInfoTableDataKeys.excursionId];
    const isPaid = data?.[ExcursionInfoTableDataKeys.isPaid];
    const selectedConfigurations = data?.[ExcursionInfoTableDataKeys.configurations];
    const selectedPreferences = data?.[ExcursionInfoTableDataKeys.selectedPreferences];
    const newExcursionId = e?.newData?.[ExcursionInfoTableDataKeys.excursionId];
    const oldExcursionId = e?.oldData?.[ExcursionInfoTableDataKeys.excursionId];
    const updates: SelectedExcursions = {
      [excursionId]: {},
    };

    if (newExcursionId || oldExcursionId) {
      set(updates, excursionId, {
        [SelectedExcursionsKeys.bookingDate]: new Date(),
        [SelectedExcursionsKeys.preferences]: selectedPreferences,
        [SelectedExcursionsKeys.isAdminSelected]: true,
        [SelectedExcursionsKeys.configurations]: selectedConfigurations,
      });
    }

    if (ExcursionInfoTableDataKeys.isPaid in e?.newData) {
      set(updates, excursionId, {
        [SelectedExcursionsKeys.isPaid]: isPaid,
      });
    }

    if (newExcursionId && oldExcursionId) {
      set(updates, oldExcursionId, deleteField());
    }

    if (attendeeType === AttendeeType.Invitee) {
      e.cancel = this.updateInviteeExcursions(conferenceId, attendeeId, updates);
    }

    if (attendeeType === AttendeeType.Guest) {
      e.cancel = this.updateGuestExcursions(conferenceId, registrantDbId, attendeeId, updates);
    }
  };

  private updateInviteeExcursions = async (
    conferenceDbId: string,
    registrantDbId: string,
    selectedExcursions: SelectedExcursions,
  ) => {
    await this.conferenceRegistrantsService.update(
      conferenceDbId,
      registrantDbId,
      {
        [RegistrantModelKeys.data]: {
          [RegistrantKeys.selectedExcursions]: selectedExcursions,
        },
      },
      true,
      this.sourceType
    );
  };

  private updateGuestExcursions = async (
    conferenceDbId: string,
    registrantDbId: string,
    guestId: string,
    selectedExcursions: SelectedExcursions,
  ) => {
    await this.conferenceGuestsService.update(
      conferenceDbId,
      registrantDbId,
      guestId,
      {
        [GuestKeys.selectedExcursions]: selectedExcursions,
      },
      true,
      this.sourceType
    );
  };

  protected onRowValidating = (e: RowValidatingEvent<ExcursionInfoTableData>): void => {
    const data = Object.assign({}, e?.oldData, e?.newData);
    const excursionId = data?.[ExcursionInfoTableDataKeys.excursionId];
    const selectedExcursion = this.conferenceExcursions?.find(
      excursion => excursion?.[ExcursionKeys.id] === excursionId,
    );
    const preferences = selectedExcursion?.[ExcursionKeys.preferences] ?? [];
    const selectedPreferences = data?.[ExcursionInfoTableDataKeys.selectedPreferences];
    const isValid = preferences?.every(preference => {
      return selectedPreferences?.[preference?.[ExcursionPreferenceKeys.label]];
    });

    e.isValid = isValid;

    const dataGrid = e.component;
    const rowIndex = dataGrid.getRowIndexByKey(e.key);

    if (!isValid) {
      dataGrid.getCellElement(rowIndex, 'selectedPreferences').classList.add('dx-validator', 'dx-datagrid-invalid');
    }
  };

  protected calculateSelectedPreferencesCellValue = (e: ExcursionInfoTableData) => {
    const selectedPreferences = e?.[ExcursionInfoTableDataKeys.selectedPreferences] ?? {};
    if (selectedPreferences.hasOwnProperty('preferences')) {
      // fix undefined on editing
      delete selectedPreferences['preferences'];
    }
    return this.calculateExcursionCostRowData(selectedPreferences);
  };

  protected calculateConfigurationsCellValue = (e: ExcursionInfoTableData) => {
    const selectedConfigurations = e?.[ExcursionInfoTableDataKeys.configurations] ?? {};

    if (selectedConfigurations.hasOwnProperty('configurations')) {
      delete selectedConfigurations['configurations'];
    }
    return this.calculateExcursionConfigurationsRowData(selectedConfigurations);
  };

  private calculateExcursionConfigurationsRowData = (selectedConfigurations: SelectedExcursionConfiguration) => {
    return Object.entries(selectedConfigurations).map(([configurationsName, configurationOption]) => {
      const configurationsNameUpdated = configurationsName.split(/(?=[A-Z])/).join(' ');
      const displayValue = this.excursionConfigurationDisplayValuePipe.transform(
        configurationOption?.value,
        configurationOption?.dataType,
      );

      const title = `${configurationsNameUpdated}: ${displayValue}`;

      return { title };
    });
  };

  protected calculateExcursionCostCellValue = (e: ExcursionInfoTableData): number => {
    const selectedPreferences = e?.[ExcursionInfoTableDataKeys.selectedPreferences] ?? {};
    const preferences = this.calculateExcursionCostRowData(selectedPreferences);
    let rowTotal = e?.[ExcursionInfoTableDataKeys.excursionCost] ?? 0;

    preferences.forEach(preference => {
      rowTotal += preference.cost ?? 0;
    });

    return !!rowTotal ? rowTotal : undefined;
  };

  private calculateExcursionCostRowData = (selectedPreferences: SelectedExcursionOptions) => {
    return Object.entries(selectedPreferences).map(([preferenceName, preferenceOption]) => {
      const title = `${preferenceName}: ${preferenceOption?.[ExcursionOptionKey.name]}`;
      const cost = preferenceOption?.[ExcursionOptionKey.cost];
      return { title, cost };
    });
  };

  protected calculateCustomSummary = options => {
    const calculateFullCost = () => {
      const excursionCost = options.value?.[ExcursionInfoTableDataKeys.excursionCost] ?? 0;
      const selectedPreferences: SelectedExcursionOptions =
        options.value?.[ExcursionInfoTableDataKeys.selectedPreferences] ?? {};
      Object.values(selectedPreferences).forEach(preference => {
        const preferenceCost = preference?.[ExcursionOptionKey.cost] ?? 0;

        options.totalValue += preferenceCost ?? 0;
      });

      options.totalValue += excursionCost;
    };
    switch (options.name) {
      case ExcursionInfoTableDataKeys.excursionCost:
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
        }
        if (options.summaryProcess === 'calculate') {
          calculateFullCost();
        }
        break;
      case 'discount':
        if (options.summaryProcess === 'start') {
          options.totalValue = this.excursionsBudget;
        }
        break;
      case 'totalCharge':
        if (options.summaryProcess === 'start') {
          options.totalValue = 0;
        }
        if (options.summaryProcess === 'calculate') {
          calculateFullCost();
        }
        if (options.summaryProcess === 'finalize') {
          const totalCharge = options.totalValue - this.excursionsBudget;
          options.totalValue = totalCharge > 0 ? totalCharge : 0;
        }
        break;
      default:
        break;
    }
  };

  createConfigurationPropertyName = (name: string) => {
    const updatedName = name.trim();
    const updatedText = updatedName.replace(/\w+/, updatedName.split(' ')[0].toLowerCase());
    return updatedText.replace(/ /g, '');
  };
}
